.ui-state-default, .ui-widget-content .ui-state-default, 
.ui-widget-header .ui-state-default,
.ui-widget-content {
  border: none;
  background: none !important;
}

.region-sidebar-first{
	.block{
		margin-left: 15px;
		&.block-accordion-menu{
			h2{
				display: none;
			}
		}
	}
	.ui-accordion{
		.accordion-header{
			text-transform: uppercase;
			padding: 0px;
			font-family: $signika;
			font-size: 17px;
			color: #030303;
			height: 52px;
			margin: 0px;
			line-height: 1;
			font-weight: 300;
			@include border-radius(transparent);
			.ui-icon{
				background: url(../images/accordian-arrows.png) no-repeat right top transparent;
				width: 14px;
				height: 7px;
				margin-top: 0px;
				top: 7px;
				&.ui-icon-triangle-1-s{
					background-position: right bottom !important;
				}
			}
			.ui-accordion-header-icon{
				left: inherit;
				right: 10%;
			}
			&.no-children .ui-icon{
				display: none;
			}
			a{
				font-family: $signika;
				font-size: 17px;
				color: #030303;
				&.active, &:hover{
					text-decoration: underline;
					text-decoration-color: #666666;
				}
			}
		}
		.ui-widget-content{
			padding: 0px 0px 30px 10px;
			margin-top: -27px;
			margin-bottom: 20px;
			.nav li{
				max-width: 148px;
				a{
					background: none !important;
					padding: 0px 0px 0px 0px;
					font-family: $signika;
					color: #666666;
					font-size: 15px;
					line-height: 25px;
					font-weight: 300;
					&.active, &:hover{
						text-decoration: underline;
						text-decoration-color: #666666;
					}
				}
			}
			&:empty{
				max-height: 0px;
				height: 0px !important;
				display: none !important;
			}
		}
	}
}

// return button on gallery pages

// return button on gallery and event pages
#block-bean-return-to-photo-gallery, 
#block-bean-return-to-news-events{
	@include return-button;
}

#block-bean-return-to-news-events{
	.field-name-field-gb-link a{
		width: 150px;
	}
}

// filter navigation of events
#block-menu-menu-events-and-news-lhs-menu{
	h2{
		font-size: 18px;
		text-transform: uppercase;
		color: #020202;
		font-family: $signika;
	}
	.menu li a{
		background: none;
		padding: 0px 0px 5px 0px;
		color: $darkgray;
		&.active{
			text-decoration: underline;
		}
	}
}

.view-news-events-taxonomy-block{
	.views-row a{
		display: block;
		line-height: 1;
		color: $darkgray;
		padding: 9px 0px;
		&.active{
			text-decoration: underline;
		}
	}
}


// skiing left menu
#block-accordion-menu-4{
	#ui-accordion-1-header-0, #ui-accordion-2-header-0{
		height: 82px;
		 a{
			padding-right: 48px;
			display: block;
			line-height: 1.3;
		}
	}
	#ui-accordion-1-panel-0, #ui-accordion-2-panel-0{
		margin-top: -20px;
	}
}

// snowboarding left menu
#block-accordion-menu-5{
	#ui-accordion-1-header-0, #ui-accordion-2-header-0{
		height: 82px;
		 a{
			display: block;
			line-height: 1.3;
			padding-right: 40px;
		}
	}
	#ui-accordion-1-panel-0, #ui-accordion-2-panel-0{
		margin-top: -20px;
	}
}