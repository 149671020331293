$border:       #ccc;    // all borders set in global.styles - tables, fieldset, primary links
$highlight:    #c00;    // required mark, form error highlight, "new" label on comments
$debug:        rgba(255, 192, 203, 0.5); // Full width wrapper test
$white:        #fff; // basic white
$black:        #000; // basic black
$grey:         #3f3f3f; // the grey used in the top nav background
$green:        #6ba743; // the green used just above the footer
$skip:         #444; //skip link color
$darkgray:     #666666;
$grayhead:     #8c8c8c;
$bg_01:        #f5f5f5;
$bg_02:        #f8f8f8;
$red:          #ee3534;
$color:        #333333;
$color2:       #51b6ef; //sky blue color
$color3:       #ef342d; //red color
$color4:       #85ccf4;
$color5:       #d5dbdf;
$color6:       #51b5f0;
$color7:       #cbdcf0;
$color8:       #2f2f2f; //heading color
$medium_dark:  #e5e5e5;
$page:         #fff;
$ultralight:   #f5f5f5;
$light:        #eee;
$medium_light: #ebebeb;

$thead:          $medium_dark;
$tr_odd:         $page;
$tr_even:        $ultralight;
$tr_odd_active:  $light;
$tr_even_active: $medium_light;

$primary_task:        $ultralight;
$primary_task_hover:  $light;
$primary_task_active: $page;


//
// Font Variables
// These font stacks are an exact copy of the stacks found in AT Cores
// theme settings for web safe fonts.
//

$sans-serif-small:            Helvetica, 'Helvetica Neue', Arial, sans-serif;
$sans-serif-large:            Verdana, Geneva, 'DejaVu Sans', Arial, Helvetica, sans-serif;
$sans-serif-arial-helvetica:  Arial, Helvetica, sans-serif;
$calibri-candara:             Calibri, Candara, Arial, Helvetica, sans-serif;
$serif-small:                 Garamond, Perpetua, 'Times New Roman', serif;
$serif-large:                 Georgia, Baskerville, Palatino, 'Palatino Linotype', 'Book Antiqua', 'Times New Roman', serif;
$modern-myriad:               'Segoe UI', 'Myriad Pro', Myriad, Arial, Helvetica, sans-serif;
$lucida:                      'Lucida Sans Unicode', 'Lucida Sans', 'Lucida Grande', Verdana, Geneva, sans-serif;
$impact:                      Impact, Haettenschweiler, 'Franklin Gothic Bold', Charcoal, 'Helvetica Inserat', 'Bitstream Vera Sans Bold', 'Arial Black', sans-serif;
$mono:                        Consolas, Monaco, 'Courier New', Courier, monospace, sans-serif;
$signika:                     'Signika', sans-serif;
$signikaNegative:             'Signika Negative', sans-serif;
$robotoRegular:               'Roboto', sans-serif;
$robotoCondensed:             'Roboto Condensed', sans-serif;
$opensans:                    'Open Sans', sans-serif;

// Gutters
$gutter-width: 10px;

@mixin cf {
  overflow: hidden;   // New formatting context in better browsers
  *overflow: visible; // Protect IE7 and older from the overflow property
  *zoom: 1;           // Give IE hasLayout, a new formatting context equivalent
}

@mixin ir {
  // Kellum Method
  display: block !important;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  // Additional helpers
  border: 0;                     // Remove the default border from elements like <button>
  font: 0/0 a;                   // Crush the text down to take up no space
  text-shadow: none;             // Remove any text shadows
  color: transparent;            // Hide any residual text in Safari 4 and any mobile devices that may need it
  background-color: transparent; // Hide the default background color on elements like <button>
}

// element-invisible improved and more robust
@mixin element-invisible {
  border: 0;
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    clip: rect(1px 1px 1px 1px); // IE6 and IE7 use the wrong syntax
  }
  clip: rect(1px, 1px, 1px, 1px);
}

// Turns off the element-invisible effect
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

// Shift content offscreen, can be usefull when you reall need to do this
@mixin offscreen {
  position: absolute;
  top: -99999em;
  width: 1px;
  height: 1px;
  overflow: hidden;
  outline: 0;
}

// Hide content from all users
@mixin element-hidden {
  display: none;
}

@mixin box-shadow($clr: $color, $v1:0px , $v2:0px, $v3:5px) {
  box-shadow: $v1 $v2 $v3 $clr;
  -moz-box-shadow: $v1 $v2 $v3 $clr;
  -webkit-box-shadow: $v1 $v2 $v3 $clr;
}

@mixin text-shadow($clr: $color, $v1:0px , $v2:0px, $v3:5px) {
  text-shadow: $v1 $v2 $v3 $clr;
  -moz-text-shadow: $v1 $v2 $v3 $clr;
  -webkit-text-shadow: $v1 $v2 $v3 $clr;
}

// for opacity
@mixin opacity($value: .8){
  $IEValue: $value*100;
  opacity: $value;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
  filter: alpha(opacity=$IEValue);
}

// The word "Unpublished" displayed underneath unpublished nodes and comments
@mixin unpublished {
  color: pink; // target browsers that do not support rgba
  color: rgba(239, 170, 170, 0.4);
  font-family: $impact;
  font-size: 5.0em;
  font-weight: 700;
  line-height: 1.2;
  height: 0;
  margin: 0;
  padding: 0;
  overflow: visible;
  text-align: center;
  text-transform: uppercase;
  word-wrap: break-word;

  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    .ie6-7 &>* {
      position: relative; // Otherwise these elements will appear below the "Unpublished" text.
    }
  }
}

@mixin full-width($w: 100%, $dir: left) {
  width: $w;
  float: $dir;
}

@mixin inline {
  display: inline-block;
  vertical-align: top;
  zoom: 1;
  *display: inline;
}

@mixin border-radius($b: 5px) {
  border-radius: $b;
  -moz-border-radius: $b;
  -webkit-border-radius: $b;
}

@mixin button-red($fs: 18px, $p: 13px 0px 11px 0px, $w: 174px) {
  background: $color3;
  color: white;
  text-align: center;
  padding: $p;
  font-size: $fs;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  width: $w;
  display: block;
  line-height: 1;
  &:hover{
    background: darken($color3, 7%);
  }
}

@mixin button-blue($fs: 18px, $p: 13px 0px 12px 0px, $w: 123px) {
  background: $color2;
  color: white;
  text-align: center;
  padding: $p;
  font-family: $signika;
  font-size: $fs;
  text-transform: uppercase;
  text-decoration: none;
  width: $w;
  display: block;
  line-height: 1;
  font-weight: 400;
  &:hover{
    background: darken($color2, 7%);
  }
}

@mixin social-links() {
  .nav{
    li{
      float: left;
      padding: 0px;
      a{
        color: white;
        padding: 0px;
        width: 50px;
        height: 50px;
        display: block;
        color: transparent;
        font-size: 0px;
        margin-left: 1px;
        background: url(../images/social-icons.png) no-repeat 13px 10px #84afd7;
        &:hover{
          background-color: darken(#84afd7, 7%);
        }
        &.facebook-menu{
          background-position: 13px 10px;
        }
        &.youtube-menu{
          background-position: -89px 12px;
        }
        &.twitter-menu{
          background-position: -38px 13px;
        }
        &.instagram-menu{
          background-position: -138px 11px;
        }
      }
    }
  }
}

// gray heading
@mixin gray-heading() {
  font-size: 18px;
  color: #a7a6a6;
  text-transform: uppercase;
  border-bottom: solid 1px #b7b7b7;
  line-height: 30px;
  padding: 0px;
  margin: 0px;
}

@mixin event-tabs() {
  border-bottom: solid 50px $color2;
  float: left;
  width: 100%;
  top: 0px;
  margin-top: 24px;
  margin-bottom: 102px;
  h2{
      display: none;
  }
  .quicktabs-tabpage, ul.quicktabs-tabs,
  .views-club-events-wrapper, .quicktabs_main,
  .quicktabs-views-group {
      float: left;
      width: 100%;
  }
  ul.quicktabs-tabs{
    background: $color4;
    border: none;
    height: 34px;
    position: relative;
    border-bottom: solid 1px white;
    li{
      background: none;
      line-height: 34px;
      height: 34px;
      min-height: 34px;
      margin: 0px;
      position: relative;
      border-right: solid 1px white;
      a{
        border: none !important;
        background: $color4;
        font-family: $signika;
        font-size: 18px;
        font-weight: 300;
        text-transform: uppercase;
        color: white;
        height: 34px;
        line-height: 34px;
        min-height: 34px;
        padding: 0 15px;
        margin: 0px;
      }
      &.first a{
        text-indent: 13px;
      }
      &.last{
        border-right: 0px;
      }
      &.active a, &:hover a{
        background: $color2;
      }
      &.active{
        border-bottom: solid 1px #51b6ef;
        &:before{
          display: none;
        }
      }
      &:before{
        background: white;
        height: 1px;
        content: "";
        left: 0px;
        bottom: 0px;
        width: 100%;
        position: absolute;
        z-index: 5;
      }
    }
  }
  .quicktabs_main{
    border: none;
    position: relative;
    padding: 50px 0px 0px 0px;
    margin: 0px;
    top: 0px;
    &:before, &:after{
      height: 50px;
      top: 0px;
      position: absolute;
      color: white;
      font-weight: 400;
      font-size: 18px;
      line-height: 50px;
    }
      &:before{
        background: $color2;
        content: "DAY";
        left: 0px;
        width: 100%;
        text-indent: 30px;
        line-height: 50px;
      }
      &:after{
        font-weight: 400;
        content: "EVENT";
        left: 209px;
        width: 300px;
      }
      .quicktabs-views-group{
        border-bottom: solid 1px $color5;
        line-height: 39px;
        font-family: $signika;
        font-size: 16px;
        .views-club-events-date{
          float: left;
          width: 180px;
          padding-left: 30px;
          border-right: solid 1px $color5;
          .date-display-single span{
            text-transform: uppercase;
          }
        }
        .views-club-events-title{
            float: left;
            width: 500px;
            padding-left: 30px;
            a{
                color: #000001;

            }
        }
        &:last-child{
            border: none;
        }
    }
  }
}

@mixin landing-heading($c: $color2) {
  background: $c;
  width: 100%;
  z-index: 111;
  text-transform: capitalize;
  font-weight: 300;
  color: white;
  line-height: 1.2;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: .9;
  padding: 8px 0px 8px 10px;
}

@mixin landing-block-heading {
  @include landing-heading($color3);
}

@mixin landing-snowboarding-heading {
  @include landing-heading;
}

@mixin field-top-heading {
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 400;
  color: $color8;
  margin-top: 0px;
}

@mixin field-heading {
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  padding-bottom: 10px;
  margin-top: 40px;
}

@mixin field-listing {
  background: url(../images/check.png) no-repeat left top transparent;
  padding-left: 32px;
  padding-bottom: 12px;
  font-size: 14px;
  font-weight: 300;
}

@mixin main-menu {
  // main level
  .tb-megamenu{
    background: rgba(232, 241, 248, .9);
  }
  .nav{
    li{
      background: none;
      &.the-slope-menu > a{
        width: 128px;
        text-align: center;
      }
      &.skiing-menu > a{
        width: 215px;
        text-align: center;
      }
      &.snowboarding-menu > a{
        width: 230px;
        text-align: center;
      }
      &.pricing-booking-menu > a{
        width: 210px;
        text-align: center;
      }
      &.news-events-menu > a{
        width: 190px;
        text-align: center;
      }
      &.active, &:hover{
         > a{
          text-decoration: underline;
        }
      }
      > a{
        border: none;
        background: none !important;
        color: #2f2f2f !important;
        text-transform: uppercase;
        font-weight: 300;
        padding: 17px 0px 17px 0px;
        line-height: 1;
        .caret{
          display: none !important;
        }
      }
    }
  }
  .tb-megamenu-column{
    width: 152px !important;
  }
  #tb-megamenu-column-2, #tb-megamenu-column-6,
  #tb-megamenu-column-13{
    margin-left: 6px;
  }
  // about us
  #tb-megamenu-column-3{
    margin-left: 0px;
  }
  // about video
  #tb-megamenu-column-4{
    margin-left: 36px;
  }
  #tb-megamenu-column-5 {
    margin-left: 42px;
  }
  #tb-megamenu-column-6 {
    margin-left: 93px;
  }
  // skiing-menu packages
  #tb-megamenu-column-8{
    margin-left: 6px;
  }
  // skiing-menu sessions
  #tb-megamenu-column-10{
    margin-left: 34px;
  }
  // skiing-menu video
  #tb-megamenu-column-11{
    width: 300px !important;
  }
  #tb-megamenu-column-12{
    margin-left: 34px;
  }
  #tb-megamenu-column-13 {
    margin-left: 103px;
  }
  // snowboarding-menu packages
  #tb-megamenu-column-15{
    margin-left: 6px;
  }
  // snowboarding-menu sessions
  #tb-megamenu-column-17{
    margin-left: 33px;
  }
  #tb-megamenu-column-19 {
    margin-left: 36px;
  }
  // snowboarding-menu video
  #tb-megamenu-column-20{
    margin-left: 102px;
  }

  .dropdown-menu{
    padding-top: 15px;
  }
  // second level
  .tb-megamenu-item {
    > .tb-megamenu-submenu{
      border: none;
      min-height: 250px;
      .level-2 > a{
        font-size: 14px;
        font-weight: normal !important;
        color: black;
      }
      // third level
      .tb-megamenu-submenu{
        border: none !important;
         a{
          font-size: 13px;
          color: #666666 !important;
          text-transform: capitalize;
        }
      }
    }
  }
}


// pagination
@mixin pagination {
  text-align: right;
  .pagination {
    margin: 0 -17px 0 0;
    @include border-radius(0px);
      > li > a,
      > li > span {
        border: none;
        @include border-radius(0px);
        color: #737373;
        background: none !important;
    }
    li.active a{
      color: $color2;
    }
  }
}

// event slider
@mixin jcarousel {
  background: #efefef;
  padding: 60px 87px 0px 87px;
  height: 300px;
  overflow: hidden;
  .jcarousel-container-horizontal{
    padding: 0px;
    width: 580px;
    height: 185px;
    .jcarousel-clip-horizontal{
      width: 580px !important;
    }
    .jcarousel-navigation{
      display: none;
    }
    .jcarousel-item{
      width: 274px !important;
      height: 185px;
      margin-right: 30px;
      margin-left: 0px !important;
      border: none !important;
    }
  }
  .jcarousel-prev, .jcarousel-next{
    height: 47px;
    width: 23px;
    top: 60px;
    background-repeat: no-repeat;
  }
  .jcarousel-prev{
    background: url(../images/scroll-prev.png);
    left: -50px;
  }
  .jcarousel-next{
    background: url(../images/scroll-next.png);
    right: -50px;
  }
}

// return button
@mixin return-button() {
  .field-name-field-gb-link a{
    background: none;
    width: 132px;
    color: $color8;
    font-size: 17px;
    text-transform: uppercase;
    text-align: left;
    font-weight: 400;
    position: relative;
    padding-left: 15px;
    padding-top: 0px;
    margin-top: 0px;
    &:before{
      content: "<";
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }
}

// top right menu
@mixin topright-menu {
  text-align: right;
  float: right;
  margin-top: 18px;
  .nav li{
    float: left;
    padding: 0px;
    &.first a{
      padding-left: 0px;
      border: none;
    }
    a{
      padding: 0px 7px 0px 7px;
      background: none !important;
      font-family: $signika;
      font-size: 14px;
      font-weight: 300;
      color: $color7;
      line-height: 1.2;
      text-transform: uppercase;
      border-left: solid 1px $color7;
      // &.home-icon{
      //   background: url(../images/home-icon.png) no-repeat left top transparent !important;
      //   padding-left: 48px;
      //   height: 20px;
      // }
    }
  }
}

// table styles
@mixin table-style {
 table{
    margin: 30px 0px;
    border-collapse: collapse;
    border: none;
    tr{
        th{
            background: $color6 !important;
            border: white solid 1px;
            padding: 12px 0px 12px 15px;
            color: white;
            font-weight: 400;
            text-transform: uppercase;
            text-align: left;
            font-size: 14px;
            strong{
                font-size: 18px;
                font-weight: 400;
            }
            &.first-col{
                width: 110px;
            }
            &.second-col{
                width: 200px;
            }
            strong{
              color: white !important;
            }
        }
    }
    td{
        border: #d8d9db solid 1px;
        padding: 9px 0px 9px 25px;
        border-left: 0px;
        font-size: 17px;
        text-align: left;
        &:last-child{
          border-right: 0px;
        }
        &.rtecenter {
          text-align: center;
        }
    }
  }
}

// mobile-front slider
@mixin mobile-front-slider{
  .views-homepage-slider-wrapper .views-homepage-slider-image img{
    object-fit: cover;
    height: 320px;
  }
  .views-homepage-slider-wrapper .views-homepage-slider-inner {
    margin: 100px 0px 0px 13px !important;
    width: 100%;
    .views-homepage-slider-title{
      font-size: 28px;
      margin-right: 20px;
      height: 100px;
      overflow: hidden;
    }
    .views-homepage-slider-body{
      font-size: 14px;
      padding-top: 12px;
      line-height: 1;
    }
    .views-homepage-slider-read-more{
      margin-top: 20px;
      a{
        width: 212px;
      }
    }
  }
}

// footer toggle
@mixin footer-toggle() {
    padding: 10px 15px;
   .block{
    padding: 0px;
    h2{
      border-bottom: solid 1px #92b7d9;
      line-height: 40px;
      margin: 0px;
      padding-left: 0px !important;
      background: url(../images/footer-toggle.png) right 15px transparent no-repeat;
      cursor: pointer;
      &.collapsed{
        background-position: right -45px;
      }
    }
    &#block-bean-opening-hours .content{
      padding-left: 0px;
    }
    .entity, .nav{
      height: 0px;
      overflow: hidden;
      padding: 0px 0px;
    }
  }
}

// mobile accrodin main menu
@mixin mobile-main-accordian {
  display: block;
  background: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  *{
    color: #040404;
    font-family: $signika;
  }
  h2{
    background: rgba(232, 241, 248, 0.9);
    width: 100%;
    text-align: center;
    border: none;
    padding: 0px;
    margin: 0px;
    color: #040404;
    font-size: 18px;
    font-family: $signika;
    line-height: 50px;
    text-transform: uppercase;
    cursor: pointer;
    .icon-reorder{
      margin-top: 5px;
      margin-left: 10px;
      background: url("../images/toggle-icon.png") no-repeat left top transparent;
      display: inline-block;
      width: 20px;
      height: 20px;
      position: relative;
      top: 7px;
      width: 20px;
    }
  }
  .accordion-menu-wrapper{
    height: 0px;
    overflow: hidden;
  }
  .ui-accordion-content{
    height: auto !important;
    padding: 0px;
    a{
      font-size: 15px;
      padding: 12px 0px 2px 20px;
    }
  }
  .ui-accordion .ui-accordion-header .ui-accordion-header-icon{
    left: inherit !important;
    right: 10px !important;
  }
  .ui-accordion-icons{
    padding-left: 20px !important;
  }
  .no-children .ui-icon{
    display: none !important;
  }
  .ui-icon{
    background: url(../images/accordian-arrows.png) right 5px transparent no-repeat;
    width: 15px;
    height: 20px;
    &.ui-icon-triangle-1-s{
      background-position: right bottom;
      right: 15px;
    }
  }
}

// mobile carasoul
@mixin mobile-carasoul() {
  .pane-event-and-news-rotator .jcarousel-skin-default .jcarousel-container-horizontal .jcarousel-clip-horizontal{
    width: 320px !important;
  }
}

// responsive tables
@mixin responsive-tables() {
  #lessons-sessions-pricing,
  #second-table-pr, #price-membership,
  #membership{
    display: block;
    table, thead, tbody, th, td, tr {
      display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #EEE;
    border-bottom: solid 1px #51b5f0;
  }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 60%;
      font-size: 14px;
      &:nth-of-type(1):before { background: #51b5f0 !important;}
      &:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 0px;
        left: 0px;
        width: 57%;
        padding-right: 10px;
        white-space: nowrap;
        background: #88cdf2;
        line-height: 38px;
        color: white;
        padding-left: 10px;
      }
    }
  }

  #lessons-sessions-pricing{
    td:nth-of-type(1):before { content: "PRICES";}
    td:nth-of-type(2):before { content: "ADULT"; }
    td:nth-of-type(3):before { content: "STUDENT"; }
    td:nth-of-type(4):before { content: "CHILD/YOUTH"; }
  }
  #second-table-pr{
    td{
      padding-left: 40%;
      &:before{
        width: 37%;
      }
    }
    td:nth-of-type(1):before { content: "ADULT "; }
    td:nth-of-type(2):before { content: "YOUTH"; }
    td:nth-of-type(3):before { content: "SCHOOL"; }
    td:nth-of-type(4):before { content: "FAMILY"; }
    td:nth-of-type(5):before { content: "Tubing parties"; }
  }
  #price-membership{
      td{
        padding-left: 40%;
      &:before{
        width: 37%;
      }
    }
    td:nth-of-type(1):before { content: "Season Ticket"; }
    td:nth-of-type(2):before { content: "FULL"; }
    td:nth-of-type(3):before { content: "ORDINARY"; }
  }

  #membership{
    td{
        padding-left: 40%;
      &:before{
        width: 37%;
      }
    }
    td:nth-of-type(1):before { content: "Membership"; }
    td:nth-of-type(2):before { content: "FULL"; }
    td:nth-of-type(3):before { content: "ORDINARY"; }
  }

}
