body{
	&.front{
		.page-header, #block-system-main .panel-footer {
			display: none;
		}
		.main-container > .row > .col-sm-12{
			padding-left: 0px;
			padding-right: 0px;
		}
	}
}

.pane-bean-one-liner-homepage{
	text-align: center;
	position: relative;
	h2{
		line-height: 50px;
		color: white;
		font-size: 18px;
		margin: 0px;
		text-transform: uppercase;
		position: relative;
		font-weight: 400;
	}
}

// featured blocks
#mini-panel-featured_blocks_homepage{
	margin-bottom: 55px;
	.field-name-field-bs-image, .field-name-field-fb-image-video{
		font-size: 0;
		color: transparent;
		float: left;
		width: 100%;
	}
	.panel-panel{
		background: #f3f3f3;
		border-right: 2px solid white;
		height: 283px;
		> .panel-panel:hover img{
			opacity: .8;
			-moz-opacity: .8;
			-webkit-opacity: .8;
		}
		.inside{
			padding: 0px;
			margin: 0px;
		}
		h2{
			font-size: 21px;
			color: #6a6a6a;
			text-align: center;
			width: 100%;
			margin: 23px auto 0px auto;
			height: 65px;
			line-height: 27px;
			text-transform: uppercase;
			display: inline-block;
			font-weight: 300;
		}
		.field-name-field-fb-link{
			float: left;
			width: 100%;
			text-align: center;
			 a{
			 	margin: 0px auto;
				@include button-red(14px, 8px 0px, 155px);
			}
		}
		select{
			text-align: center;
			width: 200px;
			margin: 0 auto;
			border-radius: 0px;
			option{
				text-align: left;
				padding: 4px 10px;
			}
		}
		&.lastUnit{
			border: none;
		}
		.pane-bean-snowboarding-lessons .field-name-field-fb-title{
			padding: 0px 57px;
		}
		.pane-bean-become-a-member .field-name-field-fb-title{
			padding: 0px 80px;
		}
	}
}

// upcoming-events
.pane-upcoming-events{
	padding-bottom: 70px;
	overflow: hidden;
	h2{
		@include gray-heading;
		font-weight: 300;
	}
	.views-row{
		border-bottom: solid 1px #d3d3d3;
		padding: 20px 0px;
		.eve-title{
			a{
				font-weight: 400;
				color: $color;
				line-height: 1;
				display: block;
				margin-bottom: 12px;
				text-transform: capitalize;
			}
		}
		&.views-row-last{
			border: none;
			padding-bottom: 0px;
		}
		.eve-body{
			font-size: 14px;
			p{
				color: $color8;
				font-size: 14px;
			}
		}
	}
	.date-stamp{
		color: $color2;
		font-family: $opensans;
		font-weight: 600;
		width: 45px;
		margin: 0 auto;
		line-height: 1;
		text-align: center;
		.month-stamp{
			font-size: 17px;
			text-transform: uppercase;
		}
		.day-stamp{
			font-size: 30px;
		}
	}
	.eve-link{
		margin-top: 6px;
		 a{
			@include button-blue(14px, 9px 0px 8px 0px);
		}
	}
}

// fb likebox
.pane-fb-likebox{
	h2{
		@include gray-heading;
	}
	.pane-content, h2{
		margin-left: 10px;
	}
}

// pane-fb-likebox
.pane-fb-likebox{
	iframe *{
		border: none !important;
	}
}

