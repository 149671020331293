// social links section
#block-menu-menu-social-media-menu--2{
	@include social-links;
}

.footer-outer{
	background: #6c9bc7;
	position: relative;
	&:before{
		background: #a3c0db;
		height: 50px;
		width: 100%;
		content: "";
		position: absolute;
		left: 0px;
		top: 0px;
	}
}

.region-footer-top{
	position: relative;
}

#backtotop{
	position: absolute;
	background: url(../images/back-arrow.png) right 9px transparent no-repeat !important;
	border: none;
	text-transform: uppercase;
	color: white;
	left: 39%;
	padding: 0px;
	width: 185px;
	font-size: 17px;
}

.footer{
	color: #4e4d4d;
	border: 0px;
	padding: 0px;
	font-size: 14px;
	overflow: hidden;
	margin-top: 0px;
	a{
		color: #454545;
	}
	h2{
		text-transform: uppercase;
	}
	.caret{
		display: none;
	}
	.dropdown-menu{
		background: none;
		border: none;
		display: block !important;
		@include border-radius(0px);
		@include box-shadow(transparent);
		position: relative;
		margin-left: 10px;
		margin-top: 0px;
		padding-top: 0px;
		width: 100%;
		float: left;
		a{
			float: left;
			color: #454545;
			white-space: normal;
			max-width: 165px;
			font-weight: 300 !important;
			font-size: 12px;
		}
	}
}

.region-footer{
	padding: 35px 15px;
	float: left;
	width: 100%;
	position: relative;
	&:before{
		background: #84afd7;
		position: absolute;
		left: 0px;
		top: 0px;
		content: "";
		width: 100%;
		height: 100%;
	}
	.content{
		line-height: 28px;
		p{
			margin-bottom: 0px;
			padding-bottom: 0px;
		}
	}
	.block{
		position: relative;
		.nav{
			li{
				a{
					background: none !important; 
					padding: 0px !important;
					line-height: 28px;
				}
			}
		}
		h2{
			margin-top: 0px;
			margin-bottom: 15px;
		}
		strong{
			font-weight: 400;
			color: #434c54;
		}
	}
	input{
		@include border-radius(0px);
		@include box-shadow(transparent);
		border: none;
		margin-bottom: 10px;
		height: inherit;
		width: 94%;
	}
	button{
		@include border-radius(0px);
		@include box-shadow(transparent);
		border: none;
		background: #434c54;
		color: white !important;
		width: 116px;
		padding: 0px;
		line-height: 30px;
		font-size: 14px;
		margin-bottom: 40px;
		text-transform: uppercase;
		font-weight: 400;
		&:hover{
			background: lighten(#434c54,5%);
		}
	}
}


#block-bean-opening-hours{
	h2, .content{
		padding-left: 10px;
	}
}



// copyright section
.region-footer-bottom{
	overflow: hidden;
	background: #6c9bc7;
	padding: 15px 0px;
	p{
		color: #434C54;
		line-height: 32px;
		opacity: .8;
		-moz-opacity: .8;
		-webkit-opacity: .8;
		font-size: 14px;
	}
}

#block-bean-designed-and-developed-by{
	text-align: right;
	.field {
		display: inline-block;
		padding-left: 10px;
		vertical-align: top;
	}
}

#mini-panel-news_letter_sign_up_and_bottom_b{
	form{
		margin-top: 7px;
	}
}

.Championships{
	line-height: 18px !important;
}