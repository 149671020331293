#prevLink, #nextLink{
	width: 40%;
}

#desktop-slider{
	display: block;
}
#mobile-slider{
	display: none;
}
.flexslider{
	height: 400px;
	border: none;
	margin-bottom: 0px;
	@include border-radius(0px);
	@include box-shadow(transparent);
	.flex-control-nav, .flex-direction-nav{
		display: none;
	}
	.slides{
		overflow: hidden;
		padding-bottom: 62px;
		 img{
			width: auto;
		}
	}
	.views-homepage-slider-wrapper{
		.views-homepage-slider-image{
			position: absolute;
			right: 0px;
			top: 0px;
			img{
				height: 400px;
			}
		}
		.views-homepage-slider-inner{
			position: relative;
			width: 340px;
			margin-left: 30px;
			margin-top: 105px;
		}
		.views-homepage-slider-title{
			font-size: 50px;
			font-weight: 300;
			line-height: 1;
			text-transform: uppercase;
			color: #333333;
		}
		.views-homepage-slider-body{
			font-size: 18px;
			color: #666666;
			text-transform: uppercase;
			font-weight: 300;
		}
		.views-homepage-slider-read-more{
			float: left;
			width: 100%;
			margin-top: 43px;
			 a{
				@include button-red;
			}
		}
	}
}


// event-and-news-rotator
.pane-event-and-news-rotator{
    padding-top: 20px;
    h2{
        color: #494443;
        margin-bottom: 15px;
    }
    .jcarousel-skin-default{
        @include jcarousel;
    }
}