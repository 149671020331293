.tb-megamenu {
  .nav-collapse {
    display: block !important;
  }
}

#header {
  height: 150px;

  .region {
    float: left;
    width: 100%;
  }

  .region-header {
    display: flex;
    align-items: center;
    float: left;
    justify-content: flex-end;
    margin-bottom: 10px;
    width: 100%;
  }

  .logo {
    margin-top: 25px;
  }
}

.region-header {
  #block-bean-book-online-button {
    margin: 0 30px;
    .bean-link-block {
      a {
        @include button-blue(14px, 9px 0px 8px 0px);
        margin: auto;
      }
    }
  }
}

.region-header-top {
  padding-bottom: 68px;
}

// top mid menu
#block-menu-menu-to-nav {
  @include topright-menu;
}

// top social icons
#block-menu-menu-social-media-menu {
  @include social-links;
  text-align: right;
  float: right;
  padding-right: 0px;
  width: auto;
}

// Book Lessons Today!
#block-bean-book-lessons-today {
  line-height: 1;
  float: right;

  h2 {
    font-family: $robotoCondensed;
    font-size: 16px;
    text-transform: uppercase;
    float: left;
    padding-right: 9px;
    font-weight: 400;
    color: white;
    margin: 0px;
  }

  .entity {
    color: white;
    font-family: $robotoCondensed;
    float: left;
    font-weight: 300 !important;

    .field-label {
      font-weight: normal;
      display: inline-block;
    }

    .field-items, .field {
      display: inline-block;
    }
  }

  a {
    color: white;
    text-decoration: none;
  }

  .group-contactwrapper {
    margin-top: 0px;
  }
}

// main menu
.region-main-links {
  float: left;
  width: 100%;
  @include main-menu;
}

.tb-megamenu.style-blue .nav > li > a {
  border: 0px;
}

// menu hightligted on detail page
body {
  &.node-type-photo-gallery {
    .the-slope-menu .dropdown-toggle {
      text-decoration: underline;
    }
  }
}

#block-bean-your-first-visit,
#block-bean-your-first-visit--2,
#block-bean-your-first-visit--3 {
  width: 240px;
  height: 135px;

  .content:before {
    content: "Your first visit";
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
  }

  .field-type-image {
    margin-top: 16px;
  }

  .field-name-field-fb-title {
    display: none;
  }

  .field-type-video-embed-field a {
    padding: 6px 0px;
  }
}

// colorbox fix
#cboxLoadedContent {
  overflow: hidden !important;
}
