*{
	outline: none;
}

// mobile menu
#block-accordion-menu-6{
	display: none;
}

// mobile portrait/landscape
@media screen and (max-width: 980px) {
	#wrapper{
		background: url("../images/ipad-bg.jpg") center top #fefefe no-repeat;
	}
	img{
		height: auto;
	}
	#block-tb-megamenu-menu-menu{
		display: none;
	}
	#block-accordion-menu-6{
		@include mobile-main-accordian;
	}
	.region-footer input{
		max-width: 250px;
	}
	.page-slope-information-faqs{
		 .region-content .ui-accordion-header .faq-counter{
			width: 17px;
			float: left;
		}
		.faq-title{
			float: left;
		 	width: 90%;
		}
		 .views-field-field-faq-answer{
		 	padding-left: 18px;
		}
	}
	.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2,
	.col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4,
	.col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5,
	.col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7,
	.col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8,
	.col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10,
	.col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11,
	.col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12,
	.container{
		padding-left: 10px;
		padding-right: 10px;
	}
	.blocks-wrapper{
		overflow: hidden;
		position: relative;
	}
	.main-container{
		padding: 0px 15px;
		margin: 0px auto;
		max-width: 95%;
		.region-content{
			overflow: hidden;
		}
		.col-sm-12{
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.menu-outer{
		padding: 0px 5px;
		margin: 0px auto;
		max-width: 95%;
	}
	.region-header-top{
		display: none;
	}
	.region-header{
		float: left;
		width: 100%;
		#block-bean-book-lessons-today{
			float: left;
			padding-top: 10px;
			width: 100%;
			text-align: center;
			opacity: .7;
			-moz-opacity: .7;
			-webkit-opacity: .7;
			.field-label{
				display: none !important;
			}
			h2, .entity, .entity *{
				display: inline-block;
			}
			*{
				font-size: 15px !important;
				float: none;
				padding-right: 0px;
			}
		}
	}
	#header {
		text-align: center;
		.logo{
			float: none !important;
			height: 120px;
			img{
				height: 92px;
                margin-top: 15px;
			}
		}
	}

	.region-main-links .tb-megamenu-item > .tb-megamenu-submenu{
    	min-height: inherit !important;
  	}
	.flexslider {
		@include mobile-front-slider;
	}
	h1{
		font-size: 28px;
	}
	.pane-bean-one-liner-homepage {
		background: #a3c0db;
		h2{
			font-size: 16px;
			max-height: 50px;
			overflow: hidden;
		}
	}
	.panel-panel{
		width: 100% !important;
		&.lastUnit{
			float: left !important;
		}
	}
	#mini-panel-featured_blocks_homepage{
		margin-bottom: 0px;
		.field-name-field-bs-image, .field-name-field-fb-image-video{
			text-align: center;
		}
		.panel-panel .panel-panel{
			min-height: 285px;
			padding-bottom: 20px;
			border: none !important;
			h2{
				height: 35px;
				padding-left: 0px !important;
				padding-right: 0px !important;
			}
		}
		.skiing-select-list{
			text-align: center;
			select{
				display: inline-block;
				margin-left: 0px;
			}
		}
	}
	.pane-upcoming-events{
		margin-top: 40px;
		.col-md-2{
			width: 23%;
			float: left;
		}
		.col-md-7{
			width: 70%;
			float: left;
		}
		.col-md-3{
			width: 100%;
			float: left;
			.eve-link{
				margin-left: 24%;
			}

		}
	}

	iframe{
		width: 100% !important;
		height: auto !important;
	}

	#backtotop{
		display: none !important;
	}

	.region-footer{
		@include footer-toggle;
		.block .nav li a{
			line-height: 32px;
		}
	}
	.region-footer-bottom{
		text-align: center;
		 p{
			font-size: .88em;
		}
	}
	#block-menu-menu-social-media-menu--2{
		padding-left: 0px;
	}

	// inner pages
	.breadcrumb-outer{
		display: none;
	}
	body.not-front{
		 .main-container{
			padding: 34px 15px;
		}
		&.one-sidebar .region-content{
			padding-right: 0px;
		}
		.main-container > .row > .col-sm-3,
		.moscone-flipped-sidebar {
			display: none;
		}
	}
	#block-bean-return-to-news-events{
		margin-left: 0px;
		.field-name-field-gb-link a{
			width: 100%;
		}
	}

	#block-bean-homepage-block-below-newsletter,
	#block-panels-mini-news-letter-sign-up-and-bottom-b{
		h2{
			background: none !important;
			border: none !important;
		}
		.entity {
			display: block !important;
			padding-top: 0px;
		}
		form{
			margin-top: 0px;
		}
	}

	#mini-panel-contact_bottom_block{
		.panel-panel{
			padding-bottom: 30px;
			.inside{
				margin-left: 0px;
			}
			.pane-bean-our-office-contact-us .pane-content{
				max-width: 160px;
			}
		}
	}
	.pane-homepage-slider{
		height: 320px;
		overflow: hidden;
	}
	.pane-fb-likebox iframe{
		width: 97% !important;
	}
	body.page-node-61 .main-container .col-sm-9{
    	padding-left: 10px;
	}
	.news-events-list .views-row{
		padding: 20px 10px;
		&:before{
			left: 5px;
		}
	}
	.page-slope-photo-gallery{
		.view-photo-gallery{
			margin: 0px 0px;
		}
		.main-container > .row > .col-sm-9{
			width: 100%;
		}
	}

	.field-name-field-photo-gallery-images .field-item,
	.picture-gallery-block .view-content .views-row{
		margin-left: 15px;
		padding-left: 0px;
		padding-right: 15px;
		margin-bottom: 30px;
	}
	body.front{
		.lastUnit .inside {
		  	margin: 0px !important;
		}
	}

	// news event image slider
	.pane-event-and-news-rotator{
	    .jcarousel-skin-default{
		  padding: 30px 30px 0px 30px;
		  height: auto;
		  .jcarousel-container-horizontal{
		  	width: 100% !important;
		  	.jcarousel-clip-horizontal{
		  	 	width: 100% !important;
		  	}
		    .jcarousel-item{
		      width: 225px !important;
		      background: none !important;
		    }
		  }
		  .jcarousel-prev{
		    left: -28px;
		  }
		  .jcarousel-next{
		    right: -30px;
		  }
	    }
	}

	.field-name-body{
		@include responsive-tables;
	}
	body.page-node-68 .field-name-body table {
	    min-height: 596px;
	}
}

@media screen and (min-width: 321px) and (max-width: 375px){
	@include mobile-carasoul;
}

@media screen and (min-width: 568px) and (max-width: 600px){
	#block-menu-menu-social-media-menu, #block-menu-menu-to-nav{
		float: right;
	}
	#block-menu-menu-to-nav{
		margin-top: 10px;
	}
	.region-header-top{
		padding-bottom: 0px;
	}
	.region-header{
		 #block-bean-book-lessons-today{
			text-align: right;
			padding-right: 10px;
			position: absolute;
			right: 0px;
			top: 76px !important;
		}
	}
	#header{
		height: 120px;
		.col-md-3{
			float: left;
			padding-left: 0px;
			padding-right: 0px;
			padding-top: 10px !important;
			img{
				margin-left: 10px !important;
			}
		}
		.col-md-9{
			float: right;
			width: 50%;
			.region-header-top{
				display: block !important;
			}
		}
	}
	.news-events-list .views-row{
		margin-bottom: 20px;
		padding: 0px 10px 0px 0px;
		margin-left: 0px;
		&:before {
		  left: 0;
		  width: 97% !important;
		}
		.date-display-single{
			padding-bottom: 8px;
			font-size: 13px;
		}
		.views-event-news-title{
			padding-top: 8px;
			font-size: 15px;
		}
		.views-event-news-body{
			height: 40px;
			overflow: hidden;
		}
		.col-md-4 {
			width: 33.3333% !important;
			float: left;
			padding: 0px;
		}
		.col-md-8 {
			float: left;
		  	width: 66.6667% !important;
		}
	}
}

// tablet portrait
@media screen and (min-width: 601px) and (max-width: 768px) {
	.view.landing-block .views-row, .view.landing-snowboarding-block .views-row {
		padding: 0px;
		width: 30% !important;
		overflow: hidden;
		margin-left: 15px;
		margin-right: 0px;
		display: inline-block;
	}
	.region-header{
		position: relative;
		 #block-bean-book-lessons-today{
			text-align: right;
			padding-right: 10px;
			position: absolute;
			right: 0px;
			top: 95px;
		}
	}
	#header{
		height: 135px;
		.col-md-3{
			float: left;
			padding-left: 0px;
			padding-top: 30px;
			padding-right: 0px;
			img{
				margin-left: 10px;
			}
		}
		.col-md-9{
			float: right;
			.region-header-top{
				display: block !important;
			}
		}
	}
	#mini-panel-featured_blocks_homepage{
		.panel-panel{
			border: none !important;
			.panel-panel{
				width: 50% !important;
				height: auto;
			}
		}
	}
	.landing-block .views-row{
		width: 49% !important;
		float: left;
		vertical-align: top;
		margin-right: 10px;
	}
	.field-name-field-photo-gallery-images .field-item,
	.picture-gallery-block .view-content .views-row{
		width: 46% !important;
		float: left;
		vertical-align: top;
	}
	.flexslider .views-homepage-slider-wrapper .views-homepage-slider-inner {
	  max-width: 340px;
	}
	.news-events-list .views-row{
		margin-bottom: 20px;
		padding: 0px 10px 0px 0px;
		margin-left: 0px;
		&:before {
		  left: 0;
		  width: 97% !important;
		}
		.date-display-single{
			padding-bottom: 8px;
			font-size: 13px;
		}
		.views-event-news-title{
			padding-top: 8px;
			font-size: 15px;
		}
		.views-event-news-body{
			height: 40px;
			overflow: hidden;
		}
		.col-md-4 {
			width: 33.3333% !important;
			float: left;
			padding: 0px;
		}
		.col-md-8 {
			float: left;
		  	width: 66.6667% !important;
		}
	}
	body.not-front {
		.main-container > .row > .col-sm-3,
		.moscone-flipped-sidebar {
	  		display: none;
		}
		.main-container{
			> .row {
				 > .col-sm-3{
					width: 25%;
					float: left;
					display: none;
				}
				> .col-sm-9{
					width: 100%;
					float: left;
				}
			}
		}
	}
}

// tablet landscape
@media screen and (min-width: 768px) and (max-width: 1024px) {
	.field-name-field-photo-gallery-images .field-item,
	.picture-gallery-block .view-content .views-row {
	  width: 31% !important;
	}
	.pane-bean-one-liner-homepage h2{
		line-height: 51px;
	}
	#mini-panel-featured_blocks_homepage .panel-panel .pane-bean-become-a-member .field-name-field-fb-title{
		padding: 0 35px;
	}
	.landing-block .views-row {
	    padding-right: 0px;
	    overflow: hidden;
	}
	.pane-event-and-news-rotator{
	    .jcarousel-skin-default{
		  padding: 60px 70px 0px 70px;
		  .jcarousel-container-horizontal{
		    .jcarousel-item{
		      width: 260px !important;
		      background: none !important;
		    }
		  }
		  .jcarousel-next{
		    right: -15px;
		  }
	    }
	}
}

// desktop
@media screen and (min-width: 1025px) {
	.region-main-links .nav li.pricing-booking-menu > a {
	  width: 280px;
	}
	// page user
	body.page-user {
	    .main-container > .row > .col-sm-12{
	    	margin-left: 20px;
	    	width: 97%;
	    }
	}
}

// for all mobile and tablets
@media screen and (max-width: 1024px) {
	.pane-fb-likebox{
		display: none !important;
	}
	#block-bean-designed-and-developed-by{
		text-align: center;
	}
	#prevLink, #prevLink.force_show_nav, #framePrevLink {
	  background: url(../images/prev.jpg) left 95% no-repeat !important;
	  display: block !important;
	  margin-left: 30px;
	}

	#nextLink, #nextLink.force_show_nav, #frameNextLink {
	  background: url(../images/next.jpg) right 95% no-repeat !important;
	  display: block !important;
	}
}

// for all mobile
@media screen and (max-width: 767px) {
	.pane-bean-one-liner-homepage{
		display: none !important;
	}
	#block-views-club-events-block-1 .quicktabs_main .quicktabs-views-group .views-club-events-date {
	  border-right: none;
	}

	//slider
	.flexslider .views-homepage-slider-wrapper{
		.views-homepage-slider-inner{
			.views-homepage-slider-title{
				background: #fff;
				opacity: 0.9;
				display: inline;
			}
			.views-homepage-slider-body{
				display: none;
			}
		}
	}
}

// for all mobile and tablets
@media screen and (min-width: 980px) and (max-width: 1070px) {
	.region-main-links .nav{
		text-align: center;
		li{
			display: inline-block;
			> a {
			  	width: inherit !important;
			    padding: 17px 45px;
			}
		}
		.dropdown-menu{
			text-align: left;
		}
	}
}

@media screen and (min-width: 768px) and (max-width: 980px){
	iframe{
		min-height: 340px !important;
	}
}

// tablet landscape
@media screen and (min-width: 769px) and (max-width: 990px) {
	.landing-snowboarding-block, .landing-block{
		.views-row {
		  display: inline-block;
		}
	}
}

@media screen and (max-width: 768px){
	#bottomNavZoom{
		display: none !important;
	}
}

@media screen and (max-width: 667px){
	.main-container .region-content {
	    overflow: inherit;
	}
}
