body {
  font-family: $signika;
  font-weight: 300;
  font-size: 16px;
}

html, body {
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $signika;
  font-weight: 300;
}

h1 {
  font-size: 32px;
  text-transform: uppercase;
  line-height: 1;
  color: $color8;
}

h2 {
  font-size: 16px;
  color: $color;
}

input, select {
  font-family: $signika;
  font-size: 14px;
  font-weight: 300;
  color: #adadad;
}

select {
  text-transform: uppercase;
}

#wrapper {
  background: url("../images/body-bg.jpg") center top #fefefe no-repeat;
}

.container {
  max-width: 1050px;
  width: 100%;
}

.field-collection-container {
  border: none;
  margin-bottom: 0px;
}

.field-collection-view {
  border: none;
  margin: 0px;
}

img {
  max-width: 100%;
}

.page-header {
  border: none;
  padding-bottom: 0px;
}

.well {
  background-color: transparent;
  border: none;
  @include border-radius(0px);
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.05) inset !important;
  padding: 0px !important;
}

.breadcrumb {
  background-color: $color3;
  @include border-radius(0px);
  padding: 0px 26px;
  margin-bottom: 0px;
  color: #ffffff;
  font-size: 14px;
  line-height: 40px;
  .delimiter, a {
    color: #e2e2e2;
  }
}

.unit .inside {
  margin: 0em;
}

body.not-front {
  .main-container {
    background: url("../images/main-bg.png") center top transparent repeat-y;
    overflow: hidden;
    padding-top: 34px;
    padding-bottom: 60px;
    position: relative;
  }
  &.one-sidebar {
    .region-content {
      padding-right: 30px;
    }
  }
}

// skiing landing pages
.landing-block {
  margin-top: 34px;
  margin-bottom: 140px;
  .blocks-wrapper {
    overflow: hidden;
    position: relative;
  }
  .views-field-title {
    @include landing-block-heading;
  }
  .views-row {
    position: relative;
  }
  img {
    margin-bottom: 30px;
    &:hover {
      opacity: .9;
    }
  }
  iframe {
    width: 230px;
    height: 160px;
    margin-bottom: 30px;
  }
}

// snowboading landing pages
.landing-snowboarding-block {
  margin-top: 34px;
  margin-bottom: 140px;
  .blocks-wrapper {
    overflow: hidden;
    position: relative;
  }
  .views-field-title {
    @include landing-snowboarding-heading;
  }
  .views-row {
    position: relative;
  }
  img {
    margin-bottom: 30px;
    &:hover {
      opacity: .9;
    }
  }
  iframe {
    width: 230px;
    height: 160px;
  }
}

.field-name-field-sb-image-video,
.field-name-field-fb-image-video,
.field-name-field-mem-image-video,
.field-name-field-skiing-image-video {
  display: none;
}

// club events
#block-views-club-events-block-1 {
  @include event-tabs;
}

.field-name-field-mem-image,
.field-name-field-skiing-image,
.field-name-field-slope-image,
.field-name-field-slope-map,
.field-name-field-ne-image,
.field-name-field-sb-image {
  margin-bottom: 32px;
}

// membership page
.field-name-field-mem-link, .field-name-field-sk-link {
  a {
    @include button-red(15px, 10px 0px, 194px);
    font-weight: 400;
  }
}

// uploaded files section at content bottom
.page-node-68 {
  .field-name-field-mem-upload-files {
    margin-top: 20px;
  }
}

.field-name-field-mem-upload-files {
  .field-collection-view {
    margin-left: 0px;
    padding-top: 0px;
    .file-icon {
      display: none;
    }
    a {
      color: $color3;
    }
  }
  .field-name-field-mem-fc-title {
    font-weight: 400;
    color: black;
    padding-bottom: 15px;
  }
  .field-name-field-mem-fc-file .field-item {
    padding-bottom: 10px;
  }
}

.region-content .field-name-body {
  h3 {
    font-size: 18px;
  }
  ul {
    padding-left: 10px;
    margin-bottom: 20px;
    li {
      position: relative;
      padding-left: 15px;
      list-style-type: none;
      margin-left: 0px;
      margin-bottom: 10px;
      &:before {
        background: $color3;
        width: 5px;
        height: 5px;
        position: absolute;
        left: 0px;
        top: 11px;
        content: "";
        @include border-radius;
      }
    }
  }
}

body {
  &.node-type-members,
  &.node-type-pricing-and-booking {
    .field-name-body {
      @include table-style;
    }
    a {
      &.no-click-button {
        background: $color6;
        border: 1px solid $white;
        padding: 12px 20px;
        color: $white !important;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 16px;
      }
      &.white-block-button {
        background: $white;
        border: 1px solid $darkgray;
        padding: 0 8px;
        color: $darkgray !important;
        text-transform: uppercase;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}

// skiing block
body {
  &.page-node-62, &.page-node-63 {
    #block-system-main {
      border-bottom: solid 1px #7f7f7f;
      margin-bottom: 20px;
    }
  }
}

.landing-block-sking {
  .field-collection-view {
    padding-top: 0px;
  }
  .field-name-field-dblock-heading {
    @include field-top-heading;
  }
  .field-type-video-embed-field {
    margin-bottom: 30px;
  }
  // second paragraph
  .field-name-field-dblock-description {
    color: $color8;
    p:last-child {
      font-size: 14px;
    }
  }
  .field-name-field-dblock-level-of-ability {
    .field-label {
      @include field-heading;
    }
    .field-item {
      @include field-listing;
    }
  }
  .field-name-field-dblock-video-tutorial {
    .field-label {
      @include field-heading;
    }
  }
}

.field-name-field-dblock-link a {
  margin-top: 5px;
  margin-bottom: 50px;
  @include button-blue(16px, 10px 0px, 152px);
}

// slope pages
.pane-bean-ski-and-snowboard-school-about-u {
  h2 {
    font-size: 18px;
    margin-top: 0px;
  }
  .field-name-field-gb-image {
    margin-bottom: 20px;
  }
}

// pane-contact-bottom-block
.pane-contact-bottom-block {
  margin: 40px 0px 0px 0px;
  .group-contactwrapper {
    margin-top: 0px;
  }
  h2 {
    font-size: 18px;
    margin: 0px 0px 15px 0px;
    text-transform: uppercase;
  }
}

// picture-gallery-block
.picture-gallery-block {
  .view-content {
    margin-top: 40px;
    .views-row {
      margin-bottom: 48px;
    }
    .views-photo-gallery-title {
      height: 25px;
      line-height: 1;
      font-size: 14px;
      a {
        color: #040000;
      }
    }
  }

  // pagination
  .text-center {
    @include pagination;
  }

}

.field-name-field-photo-gallery-images {
  margin-top: 50px;
  .field-item {
    margin-bottom: 30px;
  }
}

.field-name-field-photo-gallery-date {
  margin-bottom: 20px;
  color: $color8;
  font-weight: 300;
}

// page-slope-information-faqs
.page-slope-information-faqs {
  .region-content {
    .ui-accordion-header {
      padding: 15px 0px;
      margin: 0px;
      border-bottom: solid 1px #e2e4e3;
      font-family: $signika;
      font-weight: 300;
      font-size: 16px;
      @include border-radius(0px);
      position: relative;
      a {
        color: #060606;
      }
      &:before {
        position: absolute;
        content: "+";
        right: 0px;
        top: 15px;
        width: 10px;
        height: 10px;
        color: black;
      }
      &:first-child {
        padding-top: 0px;
        &:before {
          top: 0px;
        }
      }
      &.ui-accordion-header-active {
        border-bottom: 0px;
        &:before {
          content: "-";
          font-size: 20px;
        }
      }
      .ui-accordion-header-icon {
        display: none;
      }
      .faq-counter {
        color: $red;
        width: 30px;
        display: inline-block;
      }
    }
    .ui-widget-content {
      height: auto !important;
      padding: 0px;
      margin: 0px;
    }
  }
  .views-field-field-faq-answer {
    font-size: 14px;
    font-family: $signika;
    font-weight: 300;
    padding-left: 33px;
    color: $color8;
    line-height: 1.5;
    max-width: 395px;
  }
}

// events and news page
.news-events-list {
  .views-row {
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    &:before {
      background: #f4f4f4;
      position: absolute;
      left: 15px;
      top: 0px;
      content: "";
      width: 97%;
      height: 500px;
    }
    &:hover {
      &:before {
        background: darken(#f4f4f4, 3%);
      }
      img {
        opacity: .9;
      }
    }
    .views-event-news-title {
      padding-top: 20px;
      padding-bottom: 8px;
      line-height: 1;
      a {
        font-size: 16px;
        font-weight: 600;
        color: #333333;
        text-decoration: none;
        text-transform: capitalize;
      }
    }
    .date-display-single {
      font-size: 14px;
      display: block;
      padding-bottom: 16px;
      line-height: 1;
      font-weight: 300;
    }
    .views-event-news-body {
      font-size: 14px;
      font-weight: 300;
      color: $color8;
    }
    .views-event-news-read-more a {
      color: $color2;
      font-size: 14px;
      font-weight: 400;
    }
  }

  // pagination
  .text-center {
    @include pagination;
  }

}

// event detail page
body.node-type-news-events {
  .footer {
    margin-top: 0px !important;
  }
  .field-name-body p {
    font-size: 14px;
    &:first-child {
      font-size: 16px;
    }
  }
}

.pane-node-field-ne-date {
  margin-bottom: 40px;
  color: #494443;
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
}

// userfull links
body {
  &.not-front {
    .region-content .field-name-body {
      padding-bottom: 20px;
      p {
        font-size: 14px;
        &:first-child {
          font-size: 15px;
          color: #3e3e3e;
          padding-bottom: 10px;
        }
        a {
          color: $color3;
          font-size: 16px;
          font-weight: 300;
        }
      }
    }
    .landing-block {
      margin-bottom: 0px !important;
    }
  }
  &.page-node-66 .region-content .field-name-body p {
    font-size: 16px;
  }
  &.node-type-photo-gallery .region-content .field-name-body {
    padding-bottom: 0px;
  }
}

// field-name-field-ne-date
// .field-name-field-ne-date .date-display-single:before{
//     content: "Posted "
// }

// slope-information-faqs
body.page-slope-information-faqs {
  .main-container {
    padding-bottom: 60px;
  }
}

// pricing pages
body.page-node-61 .main-container .col-sm-9 {
  padding-left: 40px;
}

.field-type-text-with-summary {
  a {
    color: $red;
  }
}

// lightbox
#outerImageContainer {
  padding: 10px 30px !important;
  #imageContainer {
    padding: 0px !important;
  }
  #numberDisplay {
    padding-left: 0px !important;
    font-family: $signika;
  }
}

#lightboxImage {
  object-fit: cover;
}

#imageDataContainer {
  #imageData {
    position: relative;
    #bottomNav {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
  #imageDetails {
    padding: 17px;
    width: 100%;
    #caption {
      font-family: $signika;
      font-weight: 300 !important;
      font-size: 14px;
    }
  }
}

#bottomNavClose {
  margin-top: 0px;
  font-family: $signika;
  font-weight: 300 !important;
  font-size: 14px;
  position: relative;
  background: none;
  color: black !important;
  z-index: 45456465465;
  text-decoration: none;
  width: 14px;
  &:before {
    content: "x";
  }
}

#prevLink {
  &:hover, &:visited:hover, &.force_show_nav {
    background: url("../images/prev.jpg") no-repeat 30px 100% rgba(0, 0, 0, 0);
  }
}

#framePrevLink {
  background: url("../images/prev.jpg") no-repeat 30px 100% rgba(0, 0, 0, 0);
}

#nextLink {
  margin-right: 30px;
  &:hover, &:visited:hover, &.force_show_nav {
    background: url("../images/next.jpg") no-repeat scroll right 100% rgba(0, 0, 0, 0);
  }
}

#frameNextLink {
  background: url("../images/next.jpg") no-repeat scroll right 100% rgba(0, 0, 0, 0);
}

// page-node-38
body {
  &.page-node-38, &.page-node-48, &.page-node-42 {
    .field-name-field-sb-image {
      display: none;
    }
  }
}

// page-node-68 membership page
body {
  &.page-node-68 {
    .region-content .field-name-body strong {
      font-weight: 700;
      font-size: 14px;
    }
  }
}

#sliding-popup {
  opacity: 0.9;

  .popup-content {
    #popup-text {
      margin: 20px 0 0;

      p {
        font-size: 16px;
        font-weight: normal;
        font-family: $signika;
      }
    }

    #popup-buttons {
      button {
        border-radius: 0px;
        background-color: $color2;
        color: $white;
        background-image: none;
        border: 1px solid $color2;
        text-shadow: none;
        box-shadow: inset 0 1px 0 0 $color2;
        font-family: $signika;
        font-weight: normal;

        &:hover {
          background-color: $color2;
          background-image: none;
        }
      }
    }
  }

}
