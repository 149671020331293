// rightbar blocks
.region-sidebar-second{
	margin-right: 30px;
}

.right-block{
	border: solid 1px $color5;
	border-top: $color2 solid 4px;
	margin-bottom: 35px;
	float: right;
	max-width: 210px;
	min-width: 210px;
	h2{
		border-top: solid 1px $color5;
		font-size: 24px;
		padding: 14px 0px 0px 14px;
		margin: 0px;
		width: 100%;
		overflow: hidden;
	}
	.content{
		padding: 14px;
		font-size: 15px;
		p{
			margin-bottom: 0px;
			&:first-child{
				margin-bottom: 10px;
			}
			&:empty{
				display: none;
			}
		}
	}
	.field-name-field-bl-body{
		strong{
			font-weight: 500;
		}
	}
}

// telephone/email field
.group-contactwrapper{
	margin-top: 15px;
}

.field-name-field-bl-call, .field-name-field-bl-email, 
.group-contactwrapper, .field-name-field-bl-website, .field-name-field-bl-website{
	.field-label, .field-items, .field-name-field-bl-call {
		display: inline-block;
		font-weight: 300;
	}
	a, .field-item{
		color: black;
	}
}

// block-bean-advanced-skiing-clinics-members
.field-name-field-gb-link a{
	@include button-blue(16px, 10px 0px, 100%);
	margin-top: 5px;
}