@import "variables";

// Theme specific.
@import "header";
@import "frontpage";
@import "slider";
@import "leftbar";
@import "content";
@import "rightbar";
@import "footer";
@import "responsive";


body {
  background: hotpink!important;
}
